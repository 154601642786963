import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import { Link } from 'gatsby';

const ContainerLogos = styled.section`
    background-color: #1E2E48;
    padding:6% 0%;
`
const P = styled.p`
    text-align: center;
    color:#fff;
    padding-top: 5%;
`
const Links = styled(Link)`
    text-decoration:none;
`


const LogoHome = () => {
    return (
        <ContainerLogos>
            <Container>
                <Row>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/el-faro">
                            <StaticImage src="../../../images/logo-01.png" width={200} alt="logo 1" />
                            <P>CLUB NAUTICO</P>
                        </Links>
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/el-canal">
                            <StaticImage src="../../../images/logo-02.png" width={200} alt="logo 2" />
                            <P>EL CANAL</P>
                        </Links>
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/los-lagos">
                            <StaticImage src="../../../images/logo-03.png" width={200} alt="logo 3" />
                            <P>LOS LAGOS</P>
                        </Links>
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/el-portal">
                            <StaticImage src="../../../images/logo-04.png" width={200} alt="logo 4" />
                            <P>EL PORTAL</P>
                        </Links>
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/los-bosques">
                            <StaticImage src="../../../images/logo-05.png" width={200} alt="logo 5" />
                            <P>BOSQUES</P>
                        </Links>
                    </Col>
                    <Col lg={2} md={4} sm={6} xs={6}>
                        <Links to="/barrio/los-saltos">
                            <StaticImage src="../../../images/logo-06.png" width={200} alt="logo 6" />
                            <P>SALTOS</P>
                        </Links>
                    </Col>
                </Row>
            </Container>
        </ContainerLogos>
    );
}

export default LogoHome;