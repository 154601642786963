import React from 'react';
import { Carousel } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";
import BgSLider1 from '../../../images/intro-01.png';
import BgSLider2 from '../../../images/intro-02.jpg';

const CarouseItem = styled(Carousel.Item)`
    background-image:url(${props => props.image}) ; /* The image used */
    background-color: #cccccc; /* Used if the image is unavailable */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    position: relative;
    display: inline-block;
    text-align: center;
    .text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        h1{
            color:white;
        }
    }
`


const IntroHome = () => {
    return (
        <Carousel pause="hover" fade variant="dark">
            <CarouseItem image={BgSLider2} interval={1000} className="vh-100 d-flex align-items-center justify-content-center">
                <div className="px-4 py-5 my-5 text-center text">
                    {/* <h1 className="display-5 fw-bold">¡Texto de prueba!</h1> */}
                </div>
            </CarouseItem>
            <CarouseItem image={BgSLider1} interval={1000} className="vh-100 d-flex align-items-center justify-content-center">
                <div className="px-4 py-5 my-5 text-center text">
                    {/* <h1 className="display-5 fw-bold">¡Pertenecer a la naturaleza!</h1> */}
                </div>
            </CarouseItem>
        </Carousel>
    );
}

export default IntroHome;