import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

const ContainerGeneral = styled.section`
    background-color: #fff;
    position: relative;
`
const P = styled.p`
    text-align: justify;
    color:#fff;
    padding-top: 2%;

`
const H2 = styled.h2`
    text-align: center;
    color:#fff;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`
const ContainerTexts = styled(Container)`
   position: absolute;
   z-index: 99;
   background-color: #1D2146;
   padding:4% 0%;
   right: 0;
   top:50%;
   @media (max-width:992px){
       position: initial !important;
   }
`




const aboutUs = () => {
    return (
        <ContainerGeneral >
            <Container fluid>
                <Row>
                    <Col xs={12} className="px-0">
                        <StaticImage className="w-100" src="../../../images/aboutUsBg.png" alt="background about Us" />
                    </Col>
                </Row>
            </Container>
            <ContainerTexts>
                <Row className="justify-content-center">
                    <Col lg={4} sm={6} xs={12}>
                        <H2>Objetivo</H2>
                        <P className="px-5">Formamos parte de una empresa familiar de servicios que mediante
                            el esfuerzo y el aporte de capitales, desarrolla proyectos inmobiliarios de inversión,
                            a través de los cuales brindamos la oportunidad a los interesados a pertenecer de
                            “El San Juan Villa Náutica”, ubicada en las inmediaciones del magní ico arroyo San Juan,
                            Colonia La Matilde, Municipio Paso de la Patria, Provincia de Corrientes.</P>
                    </Col>
                    <Col lg={4} sm={6} xs={12}>
                        <H2>Misión</H2>
                        <P className="px-5">Facilitar a personas y sus familias a formar parte de este
                            proyecto a través de una propuesta co creta de obtención de lotes con fines
                            habitacionales permanentes o de fines de semana, contribuyendo así a mejorar
                            de calidad de vida de la p blación que nos elige, en los aspectos social,
                            ambiental y económico.</P>
                    </Col>
                    <Col lg={4} sm={6} xs={12}>
                        <H2>Visión</H2>
                        <P className="px-5">Lograr un polo de desarrollo residencial distinguido,
                            que fomente el sentido de comunidad con actividades de esparcimiento
                            al aire libre y en contacto con la naturaleza.</P>
                    </Col>
                </Row>
            </ContainerTexts>
        </ContainerGeneral>
    );
}

export default aboutUs;