import React from 'react';
import { Row, Col, Container } from 'react-bootstrap'
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components";

const ContainerLogos = styled.section`
    background-color: #fff;
    padding:6% 0%;
`
const P = styled.p`
    text-align: center;
    color:#000;
    padding-top: 2%;
`
const H2 = styled.h2`
    text-align: center;
    color:#000;
    font-size: 3rem;
`
const H3 = styled.h3`
    text-align: center;
    color:#C1292F;
    font-size: 1.2rem;
`


const objectivesHome = () => {
    return (
        <ContainerLogos>
            <Container>
                <Row>
                    <Col xs={12}>
                        <H3>CONOCENOS</H3>
                        <H2>Objetivo</H2>
                        <P>Desarrollar un proyecto inmobiliario para la región
                            de Paso de la Patria donde El San Juan Villa Náutica,
                            sea el resultado de la sucesión de distintos barrios y
                            donde cada barrio nuevo se vaya complementando con los
                            anteriores desarrollados y con los barrios de los alrededores,
                            logrando a través de los espacios comunes a los mismos,
                            un funcionamiento integral a través de todas las actividades
                            deportivas, recreativas y de esparcimiento</P>
                    </Col>
                </Row>
            </Container>
        </ContainerLogos>
    );
}

export default objectivesHome;