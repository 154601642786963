
import React from 'react';
import SEO from '../structure/seo';
import Layout from '../structure/layout';
import Intro from '../structure/pages/home/intro';
import Logos from '../structure/pages/home/logos';
import Objectives from '../structure/pages/home/objectives';
import AboutUs from '../structure/pages/home/aboutUs';
import Portfolio from '../structure/pages/home/portfolio';

const Index = () => {
	return (
		<Layout shadowNav={true}>
			<SEO title={'Inicio'} />
			<Intro />
			<Logos />
			<Objectives />
			<AboutUs />
			<Portfolio />
		</Layout>
	);
}

export default Index;